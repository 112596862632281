import React from 'react'
import styled, { withTheme } from 'styled-components'
import Downshift from 'downshift'

import Input from './Input'
import Text from './Text'
import Spacer from './Spacer'

const positionToPlace = pos => {
  switch (pos) {
    case 1:
      return 'First'
    case 2:
      return 'Second'
    default:
      return 'Third'
  }
}

const Container = styled.div`
  position: relative;
`

const Suggestions = styled.div`
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border-radius: 0 4px;
  background: transparent;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  width: 100%;
`

const Suggestion = styled.div`
  background: ${({ highlighted, theme }) =>
    highlighted ? theme.colors.offWhite : theme.colors.white};
  font-weight: ${({ selected, theme }) =>
    selected ? theme.fontWeights.bold : theme.fontWeights.normal};
  padding: 0 ${({ theme }) => theme.spacing.x3};
  border-left: 2px solid
    ${({ highlighted, theme }) =>
      highlighted ? theme.colors.primary : theme.colors.white};
`

const AutoComplete = ({ id, athletes, last, theme, handleSelectedIds }) => {
  return (
    <Downshift
      onChange={(selection, stateAndHelpers) => {
        const id = (stateAndHelpers.id.split('-')[1] + 1) % 3
        const position = id === 0 ? 3 : id
        handleSelectedIds(selection, position)
      }}
      itemToString={item =>
        item
          ? `${item.athleteFirstname} ${item.athleteSurname} - ${
              item.horseName
            }`
          : ''
      }
    >
      {({
        inputValue,
        highlightedIndex,
        selectedItem,
        isOpen,
        getItemProps,
        getInputProps,
        toggleMenu
      }) => (
        <div>
          <Container>
            <Input
              placeholder={`${id + 1}.`}
              name={`rank_${id + 1}_result_id`}
              aria-label={`${positionToPlace(id + 1)} place rider`}
              last={last}
              onFocus={() => toggleMenu()}
              {...getInputProps()}
            />
            {isOpen && (
              <Suggestions>
                {athletes
                  .filter(
                    item =>
                      !inputValue ||
                      `${item.athleteFirstname} ${item.athleteSurname}`
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                  )
                  .map((item, index) => (
                    <Suggestion
                      {...getItemProps({
                        key: item.resultId,
                        index,
                        item,
                        highlighted: highlightedIndex === index,
                        selected: selectedItem === item
                      })}
                    >
                      <Spacer size={theme.spacing.x3} />
                      <Text
                        tag="h3"
                        size={[theme.fontScale.x4]}
                        weight={theme.fontWeights.bold}
                        lineHeight={theme.lineHeights.heading}
                      >
                        {`${item.athleteFirstname} ${item.athleteSurname}`}
                      </Text>
                      <Text
                        tag="h4"
                        size={[theme.fontScale.x3]}
                        lineHeight={theme.lineHeights.heading}
                      >
                        {`${item.horseName}`}
                      </Text>
                      <Spacer size={theme.spacing.x3} />
                    </Suggestion>
                  ))}
              </Suggestions>
            )}
          </Container>
        </div>
      )}
    </Downshift>
  )
}

export default withTheme(AutoComplete)
