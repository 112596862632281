import React from 'react'
import styled, { withTheme } from 'styled-components'

import Text from './Text'

const ErrorContainer = styled.div`
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.error};
  margin: ${({ theme }) => `${theme.spacing.x3} 0`};
  padding: ${({ theme }) => theme.spacing.x3};
`

const ErrorMessage = ({ error, theme }) => (
  <ErrorContainer>
    <Text
      tag="h2"
      size={[theme.fontScale.x4]}
      color={theme.colors.white}
      weight={theme.fontWeights.bold}
      lineHeight={theme.lineHeights.heading}
    >
      {error}
    </Text>
  </ErrorContainer>
)

export default withTheme(ErrorMessage)
