import React from 'react'
import styled from 'styled-components'

import theme from '../lib/theme'

const Th = styled.th`
  text-align: center;
  font-family: ${theme.fontFamily.sanSerif};
  font-size: ${theme.fontScale.x4};
  line-height: ${theme.lineHeights.heading};
  color: ${theme.colors.secondary};
  width: 19%;
  padding: 0 0 ${theme.spacing.x2};
  font-weight: ${theme.fontWeights.bold};
  border-bottom: 2px solid
    ${({ orderBy, attr }) =>
      orderBy === attr ? `${theme.colors.secondary}` : 'transparent'};
  &:first-child {
    width: 43%;
    text-align: left;
  }
`

const DataHeading = ({ field, value, handleOrderBy, orderBy }) => {
  return (
    <Th onClick={() => handleOrderBy(field)} orderBy={orderBy} attr={field}>
      {value}
    </Th>
  )
}

export default DataHeading
