import React from 'react'
import styled, { withTheme } from 'styled-components'

import Text from './Text'
import Spacer from './Spacer'
import Link, { WidgetLink } from './Link'

const Section = styled.section`
  margin: 0 ${props => props.theme.spacing.x3};
`
const Introduction = ({ theme, embed }) => {
  return (
    <Section>
      <Spacer size={theme.spacing.x4} />
      <Text
        tag="h1"
        size={[theme.fontScale.x6]}
        weight={theme.fontWeights.bold}
        lineHeight={theme.lineHeights.heading}
      >
        Eventing Prediction Centre
      </Text>
      <Spacer size={theme.spacing.x2} />
      <Text tag="p" size={[theme.fontScale.x3]}>
        EquiRatings and SAP have joined forces to design a statistical model that forecasts the results of major eventing competitions before and during the event. <br/>Pre-competition and post each phase, it simulates the competition 10,000 times, and updates its projected leaderboard and the estimated probability of victory, podium or top 10 for each pair.</Text>
      <Spacer size={theme.spacing.x3} />
      <Text tag="p" size={[theme.fontScale.x3]} weight={theme.fontWeights.bold}>
        See how it works:{' '}
        {embed ? (<WidgetLink href="/how-our-predictions-work" target="_blank">Our analysis explained</WidgetLink>) : ( 
          <Link to="/how-our-predictions-work">Our analysis explained</Link>
        )
        }  
      </Text>
    </Section>
  )
}

export default withTheme(Introduction)
