import React from 'react'
import styled from 'styled-components'

import theme from '../lib/theme'
import Text from './Text'
import { toPercentage } from '../lib/utils'

export const Col = styled.div`
  flex-basis: ${({ size }) => `${size}%`};
`

const displayDiff = diff => {
  const diffWithPercentage = `${(diff * 100).toFixed(0)}%`

  if (!diff) {
    return ''
  } else if (diff > 0) {
    return ` +${diffWithPercentage}`
  } else {
    return ` ${diffWithPercentage}`
  }
}

const Diff = ({ previous, current }) => {
  const diff = previous ? current - previous : null

  return (
    <Text
      size={[theme.fontScale.x4]}
      tag="span"
      weight={theme.fontWeights.bold}
      lineHeight={theme.lineHeights.heading}
      color={diff < 0 ? theme.colors.error : theme.colors.success}
    >
      {displayDiff(diff)}
    </Text>
  )
}

const PlayerViewProbability = ({ previousProbability, probability, text }) => (
  <Col size={50}>
    <Text
      size={[theme.fontScale.x7]}
      tag="h4"
      weight={theme.fontWeights.bold}
      lineHeight={theme.lineHeights.heading}
    >
      {toPercentage(probability)}
      <Diff previous={previousProbability} current={probability} />
    </Text>
    <Text
      size={[theme.fontScale.x3]}
      tag="h4"
      weight={theme.fontWeights.bold}
      lineHeight={theme.lineHeights.heading}
    >
      {text}
    </Text>
  </Col>
)

export default PlayerViewProbability
