import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

const isEmbedded = () => {
  const [name, value] = window.location.search.slice(1).split('=')
  return name === 'embed' && value === 'true'
}

ReactDOM.render(<App embed={isEmbedded()} />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
