import React from 'react'
import styled, { withTheme } from 'styled-components'

import Text from './Text'
import AutoComplete from './AutoComplete'
import { Submit } from './Input'
import ErrorMessage from './ErrorMessage'

const Section = styled.section`
  padding: ${({ theme }) => `0 ${theme.spacing.x3}`};
`

const Box = styled.div`
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background: transparent;
  margin: ${({ theme }) => `${theme.spacing.x3} 0`};
`

const actionUrl =
  process.env.REACT_APP_API_URL + '/competition_simulation_votes'

const NotVoted = ({
  handleVote,
  athletes,
  theme,
  handleSelectedIds,
  error
}) => (
  <Section>
    {error && <ErrorMessage error={error} />}
    <Text
      tag="h1"
      size={[theme.fontScale.x5]}
      weight={theme.fontWeights.bold}
      lineHeight={theme.lineHeights.heading}
    >
      Choose your Top 3
    </Text>
    <Box>
      <form onSubmit={handleVote} action={actionUrl} method="post">
        {[0, 1, 2].map(id => (
          <AutoComplete
            key={id}
            id={id}
            athletes={athletes}
            last={id === 2}
            handleSelectedIds={handleSelectedIds}
          />
        ))}
        <Submit value="Submit vote" />
      </form>
    </Box>
  </Section>
)

export default withTheme(NotVoted)
