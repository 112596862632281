import React from 'react'
import styled, { withTheme } from 'styled-components'

import Text from '../components/Text'

const LoadingContainer = styled.div`
  margin: ${props => props.theme.spacing.x3};
`

const Loading = ({ theme }) => (
  <LoadingContainer>
    <Text tag="p" size={[theme.fontScale.x3]} weight={theme.fontWeights.bold}>
      Loading…
    </Text>
  </LoadingContainer>
)

export default withTheme(Loading)
