import React, { useEffect } from 'react'
import styled from 'styled-components'

import CompetitionsListItem from './CompetitionListItem'

const Ul = styled.ul`
  list-style: none;
  padding: 0;
`

const CompetitionsList = ({ competitions }) => {
useEffect(() => localStorage.clear())
return(
<section>
    <Ul>
      {competitions.map((competition, idx) => (
        <CompetitionsListItem
          key={idx}
          competition={competition.competitionSimulation[0]}
        />
      ))}
    </Ul>
  </section>
  )
}

export default CompetitionsList
