import React from 'react'

const Movement = () => (
  <svg width="31" height="23" xmlns="http://www.w3.org/2000/svg" role="img">
    <title>Movement</title>
    <path
      d="M20.796 22.393c-.646 0-1.22-.395-1.471-.969l-3.946-9.363-3.157 5.955a1.565 1.565 0 0 1-1.04.79c-.43.107-.897 0-1.292-.251L5.73 15.613l-3.05 2.834c-.645.61-1.65.538-2.26-.108-.61-.645-.538-1.65.108-2.26l3.982-3.695a1.617 1.617 0 0 1 2.009-.143l3.767 2.654 3.91-7.318a1.563 1.563 0 0 1 1.47-.825c.61.036 1.149.395 1.4.969l3.264 7.82 3.086-14.278c.143-.61.61-1.076 1.183-1.22.61-.143 1.22.072 1.615.575l4.448 5.704c.538.681.43 1.686-.287 2.224a1.564 1.564 0 0 1-2.224-.287l-2.368-3.014-3.444 15.892a1.6 1.6 0 0 1-1.399 1.256h-.143z"
      fill="#1A3852"
      fillRule="nonzero"
      fillOpacity=".6"
    />
  </svg>
)

export default Movement
