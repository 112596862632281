import React from 'react'

const Voting = () => (
  <svg width="31" height="27" xmlns="http://www.w3.org/2000/svg" role="img">
    <title>Voting</title>
    <path
      d="M11.317 7.307H20c.18 0 .362.074.506.185.181.148.29.37.29.63v14.185h-11V14.27l2.858 1.222c.543.26 1.122.333 1.737.26a3.254 3.254 0 0 0 2.316-1.371c.579-.889.76-1.926.507-2.926 0-.111-.073-.26-.109-.37-.362-.852-.977-1.519-1.81-1.89L11.57 7.567a.839.839 0 0 1-.307-.232.937.937 0 0 0-.477.933c.065.71.485 1.356 1.227 1.679l3.327 1.42c.871.356 1.259 1.357.904 2.229-.356.872-1.357 1.26-2.228.904 0 0-8.977-3.843-9.85-4.23-.29-.13-1.485-.969-1.872-1.84C1.776 7.297.904 6.134.355 5.456.065 5.07.097 4.553.42 4.23L4.36.42A.925.925 0 0 1 5.521.355l1.582 1.066s6.685-.065 8.3 1.13c0 0 1.743 1.227 3.26 3.035.517.614.905 1.163 1.195 1.647h-8.202a.827.827 0 0 0-.34.074zm19.457 17.848c.226.55-.194 1.13-.775 1.163H1.809a.85.85 0 0 1-.776-1.163L3.94 17.89a.808.808 0 0 1 .775-.517h4.973v3.326h-2.26a.243.243 0 0 0-.227.161l-.355 1.421c-.032.162.064.29.226.29h17.696c.161 0 .258-.16.226-.29l-.355-1.42a.243.243 0 0 0-.226-.162H22.12v-3.326h4.973c.355 0 .646.194.775.517l2.906 7.265z"
      fill="#1A3852"
      fillRule="nonzero"
      fillOpacity=".6"
    />
  </svg>
)

export default Voting
