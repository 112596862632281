import React, { lazy, Suspense } from 'react'
import styled from 'styled-components'
import { importMDX } from 'mdx.macro'

import Loading from '../components/Loading'
import theme from '../lib/theme'

const Content = lazy(() => importMDX('../content/ExplainerContent.mdx'))

const MDXContent = styled.div`
  color: ${theme.colors.secondary};
  font-family: ${theme.fontFamily.sanSerif};
  font-size: ${theme.fontScale.x3};
  font-weight: ${theme.fontWeights.normal};
  line-height: ${theme.lineHeights.body};
  padding: 0 ${theme.spacing.x3} ${theme.spacing.x3};

  & h1 {
    font-size: ${theme.fontScale.x6};
    line-height: ${theme.lineHeights.normal};
    font-weight: ${theme.fontWeights.bold};
    color: ${theme.colors.secondaryLighter};
  }
  & h2 {
    font-size: ${theme.fontScale.x5};
    line-height: ${theme.lineHeights.normal};
    font-weight: ${theme.fontWeights.bold};
  }
  & h3 {
    font-size: ${theme.fontScale.x4};
    line-height: ${theme.lineHeights.normal};
    font-weight: ${theme.fontWeights.bold};
  }
  & h4 {
    font-size: ${theme.fontScale.x3};
    line-height: ${theme.lineHeights.normal};
    font-weight: ${theme.fontWeights.bold};
  }
  & h5 {
    font-size: ${theme.fontScale.x2};
    line-height: ${theme.lineHeights.normal};
    font-weight: ${theme.fontWeights.bold};
  }
  & h6 {
    font-size: ${theme.fontScale.x1};
    line-height: ${theme.lineHeights.normal};
    font-weight: ${theme.fontWeights.bold};
  }
  & p {
    margin: ${theme.spacing.x3} 0;
  }
  & img {
    width: 100%;
    margin: ${theme.spacing.x4} 0;
  }
  & a {
    color: ${theme.colors.primary};
    font-weight: ${theme.fontWeights.bold};
    transition: opacity 0.3s ease-in, text-decoration 0.3s ease-in;
  }
  & a:hover {
    opacity: 0.8;
    text-decoration: none;
  }
`

const Explainer = () => (
  <MDXContent>
    <Suspense fallback={<Loading />}>
      <Content />
    </Suspense>
  </MDXContent>
)

export default Explainer
