export const competitionName = competition => `${competition.competitionName}`

export const currentPhaseSimulations = phaseSimulations =>
  phaseSimulations ? phaseSimulations[phaseSimulations.length - 1] : null

export const extractCompetitorData = data => ({
  athleteId: data.athleteId,
  athleteFirstname: data.athleteFirstname,
  athleteSurname: data.athleteSurname,
  horseId: data.horseId,
  horseName: data.horseName
})

export const extractProbData = data => {
 const mappedData = data.map(phaseSim => {
    return phaseSim.resultSimulations.map(result => {
     return ({
       ...extractCompetitorData(result.simulationCompetitorRecord),
        resultId: result.resultId,
        one: result.simWinProb,
        two: result.simTop3Prob,
        three: result.simTop10Prob
     })
    })
  })
  return mappedData
}

export const extractTop3 = data => {
 const mappedData =data.map(result => {
    return({
          ...extractCompetitorData(result.simulationCompetitorRecord),
           resultId: result.resultId,
           one: result.simWinProb,
           two: result.simTop3Prob,
           three: result.simTop10Prob
        })
  })
  return mappedData
}

export const extractMoveData = data => {
  console.log(data)
const mappedData = data.map(phase => {
    return phase.resultSimulations.map(result => {
      return ({
        ...extractCompetitorData(result.simulationCompetitorRecord),
              resultId: result.resultId,
              one: result.simWinMove,
              two: result.simTop3Move,
              three: result.simTop10Move
      })
    })
  })
return mappedData
}

