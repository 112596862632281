import React from 'react'
import styled from 'styled-components'

import theme from '../lib/theme'
import {
  toPercentage,
  toMovementPercentage,
  movementToOpacity,
  opacifyColor
} from '../lib/utils'

const valueToOpacity = value => (value <= 0.5 ? value * 2 : 1)

const Prob = styled.td`
  font-family: ${theme.fontFamily.sanSerif};
  font-size: ${theme.fontScale.x4};
  color: ${theme.colors.secondary};
  width: 17%;
  background: rgba(255, 188, 73, ${({ opacity }) => opacity});
  text-align: center;
`

const Move = styled.td`
  font-family: ${theme.fontFamily.sanSerif};
  font-size: ${theme.fontScale.x4};
  color: ${theme.colors.secondary};
  width: 17%;
  background: ${({ value, theme }) =>
    opacifyColor(value > 0 ? theme.colors.success : theme.colors.error, value)};
  text-align: center;
`

const Val = styled.span`
  opacity: ${({ opacity }) => (opacity < 0.01 ? 0.2 : 1)};
`

const DataProbability = ({ type, value }) =>
  type === 'Prob' ? (
    <Prob opacity={valueToOpacity(value)}>
      <Val opacity={value}>{toPercentage(value)}</Val>
    </Prob>
  ) : (
    <Move value={value}>
      <Val opacity={movementToOpacity(value)}>
        {toMovementPercentage(value)}
      </Val>
    </Move>
  )

export default DataProbability
