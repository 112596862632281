import { Link } from '@reach/router'
import styled from 'styled-components'

const NewLink = styled(Link)`
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.colors.secondary};
  text-decoration: none;
`

export const BlockLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
`
export const WidgetLink = styled.a`
  color: ${props => props.theme.colors.secondary};
  text-decoration: none;
`
export default NewLink
