import { createElement as h } from 'react'
import styled from 'styled-components'

export const withDynamicTag = Component => {
  const bucket = Object.create(null)

  const DynamicTag = props => {
    const { tag } = props

    if (typeof tag !== 'string' || !styled.hasOwnProperty(tag)) {
      return h(Component, props)
    }

    if (bucket[tag] === undefined) {
      bucket[tag] = Component.withComponent(tag)
    }

    return h(bucket[tag], props)
  }

  const name = Component.displayName || Component.constructor.name

  if (name) {
    DynamicTag.displayName = `DynamicTag(${name})`
  }

  return DynamicTag
}

const TextBase = styled.p`
  cursor: ${({ cursor }) => cursor || 'auto'};
  color: ${({ color, theme }) => color || theme.colors.secondary};
  font-family: ${props => props.theme.fontFamily.sanSerif};
  font-size: ${({ size, theme }) => (size && size[0]) || theme.fontScale.x3};
  font-weight: ${({ weight, theme }) => weight || theme.fontWeights.normal};
  text-align: ${({ align }) => align || 'left'};
  line-height: ${({ lineHeight }) => lineHeight || 1.5};
  margin: 0;
  padding: 0;

  @media (${props => props.theme.breakpoints.notSmall}) {
    font-size: ${({ size, theme }) =>
      (size && size[1]) || size[0] || theme.fontScale.x3};
  }
`

export const Strong = styled.strong`
  font-weight: ${props => props.theme.fontWeights.bold};
`

export const Em = styled.em`
  text-decoration: italic;
`

export default withDynamicTag(TextBase)
