import React from 'react'

const Close = () => (
  <svg width="26" height="26" xmlns="http://www.w3.org/2000/svg">
    <g fill="#1A3852" fillRule="evenodd">
      <path d="M.272 22.9L22.9.271l2.829 2.829L3.1 25.728z" />
      <path d="M3.1.272L25.729 22.9l-2.829 2.829L.272 3.1z" />
    </g>
  </svg>
)

export default Close
