import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'

import theme from '../lib/theme'
import { Close } from './Icons'
import Text from './Text'
import Spacer from './Spacer'
import PlayerViewProbability from './PlayerViewProbability'

const Row = styled.div`
  display: flex;
  margin: 0 ${theme.spacing.x3};
  flex-wrap: wrap;
`

const modalEnter = ({ modalStartPosition }) =>
  modalStartPosition &&
  css`
    z-index: 10;
    transform: translateY(${modalStartPosition.y}px)
      scaleY(
        ${modalStartPosition.height /
          Math.max(
            document.documentElement.clientWidth,
            window.innerWidth || 0
          )}
      );
  `

const modalExit = ({ modalStartPosition }) =>
  modalStartPosition &&
  css`
    transform: translateY(${modalStartPosition.y}px)
      scaleY(
        ${modalStartPosition.height /
          Math.max(
            document.documentElement.clientWidth,
            window.innerWidth || 0
          )}
      );
  `

const Container = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${theme.colors.whiteTransparent};
  overflow-y: auto;
  transform-origin: top left;
  z-index: -1;

  &.modal-enter {
    ${modalEnter};
    & > * {
      opacity: 0;
      transform: translateY(30px);
    }
  }
  &.modal-enter-active {
    transition: all 0.5s cubic-bezier(0.19, 0, 0, 1.04);
    transform: translateY(0px) scaleY(1);
    & > * {
      transition-delay: 0.3s;
      transition: all 1s cubic-bezier(0.19, 0, 0, 1.04);
      opacity: 1;
      transform: translateY(0);
      z-index: 1;
    }
  }
  &.modal-enter-done {
    z-index: 10;
    & > * {
      z-index: 1;
    }
  }
  &.modal-exit {
    transition: all 0.3s cubic-bezier(0.19, 0, 0, 1.04);
    ${modalExit};
  }
  &.modal-exit-active {
    opacity: 0;
  }
  &.modal-exit-done {
    z-index: -1;
    & > * {
      opacity: 0;
      z-index: -1;
    }
  }
`

const CloseLink = styled.a`
  position: absolute;
  top: ${theme.spacing.x3};
  right: ${theme.spacing.x3};
`

const extractPhasesForAthlete = (phases, playerID) =>
  phases.map(phase => {
    const result = phase.resultSimulations.filter(
      res => res.resultId === playerID
    )[0]
    return { ...phase, result }
  })

const previousPhaseResult = (phases, idx) =>
  phases[idx + 1] ? phases[idx + 1].result : null

const PlayerView = ({
  handleFocusOnPlayer,
  playerID,
  data,
  modalStartPosition
}) => {
  if (!playerID) {
    return (
      <Container
        id="player-view"
        playerID={playerID}
        modalStartPosition={modalStartPosition}
      />
    )
  }

  const phases = extractPhasesForAthlete(data.phaseSimulations, playerID)
  return (
    <Container
      id="player-view"
      playerID={playerID}
      modalStartPosition={modalStartPosition}
    >
      <CloseLink href="/" onClick={e => handleFocusOnPlayer(null, e)}>
        <Close />
      </CloseLink>
      <Spacer size={theme.spacing.x5} />
      <Row>
        <Text
          size={[theme.fontScale.x6]}
          tag="h1"
          weight={theme.fontWeights.bold}
          lineHeight={theme.lineHeights.heading}
        >
          {`${
            phases[0].result.simulationCompetitorRecord.athleteFirstname
          } ${phases[0].result.simulationCompetitorRecord.athleteSurname ||
            ' '}`}
        </Text>
      </Row>
      <Spacer size={theme.spacing.x1} />
      <Row>
        <Text
          size={[theme.fontScale.x5]}
          tag="h2"
          weight={theme.fontWeights.bold}
          lineHeight={theme.lineHeights.heading}
        >
          {phases[0].result.simulationCompetitorRecord.horseName}
        </Text>
      </Row>
      {phases.map((phase, idx) => (
        <Fragment key={idx}>
          <Spacer size={theme.spacing.x4} />
          <Row>
            <Text
              size={[theme.fontScale.x4]}
              tag="h3"
              weight={theme.fontWeights.bold}
              lineHeight={theme.lineHeights.heading}
            >
              {phase.result.previousPhasePenalties
                ? `${phase.phaseType} - (${phase.result.previousPhasePenalties})`
                : `${phase.phaseType}`}
            </Text>
          </Row>
          <Spacer size={theme.spacing.x2} />
          <Row>
            <PlayerViewProbability
              previousProbability={
                previousPhaseResult(phases, idx)
                  ? previousPhaseResult(phases, idx).simWinProb
                  : null
              }
              probability={phase.result.simWinProb}
              text="Win Probability"
            />
            <PlayerViewProbability
              previousProbability={
                previousPhaseResult(phases, idx)
                  ? previousPhaseResult(phases, idx).simTop3Prob
                  : null
              }
              probability={phase.result.simTop3Prob}
              text="Top 3 Probability"
            />
          </Row>
          <Spacer size={theme.spacing.x3} />
          <Row>
            <PlayerViewProbability
              previousProbability={
                previousPhaseResult(phases, idx)
                  ? previousPhaseResult(phases, idx).simTop10Prob
                  : null
              }
              probability={phase.result.simTop10Prob}
              text="Top 10 Probability"
            />
          </Row>
          <Spacer size={theme.spacing.x5} />
        </Fragment>
      ))}
    </Container>
  )
}

export default PlayerView
