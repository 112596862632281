import React from 'react'

const Leaderboard = () => (
  <svg width="27" height="27" xmlns="http://www.w3.org/2000/svg" role="img">
    <title>Leaderboard</title>
    <path
      d="M.056 6.271c0-.436.207-.675.613-.675h.801V3.271c0-.301.028-.613.028-.613H1.48s-.095.156-.151.218c-.236.25-.557.26-.83-.062L.207 2.45c-.283-.311-.264-.654.028-.955L1.385.332C1.621.093 1.828 0 2.15 0h.641c.406 0 .622.228.622.675v4.922h.82c.406 0 .614.238.614.674v.52c0 .436-.208.675-.613.675H.669c-.406 0-.613-.24-.613-.675v-.52zM0 15.9C0 13.14 2.7 12.61 2.7 11.865c0-.42-.316-.511-.492-.511a.682.682 0 0 0-.5.225c-.27.286-.557.408-.854.143L.4 11.323c-.306-.265-.38-.572-.149-.898.353-.511 1.011-1.093 2.143-1.093 1.03 0 2.355.613 2.355 2.308 0 2.236-2.476 2.706-2.578 3.319h2.068c.399 0 .612.235.612.664v.51c0 .43-.213.664-.612.664H.64c-.371 0-.64-.235-.64-.664V15.9zm.182 8.75l.297-.542c.204-.388.482-.408.826-.184.26.123.51.184.76.184.538 0 .76-.204.76-.48 0-.388-.296-.531-.843-.531h-.25c-.316 0-.492-.123-.65-.46l-.056-.112c-.13-.276-.065-.572.149-.868l.296-.409c.362-.49.65-.786.65-.786v-.02s-.223.06-.668.06H.767c-.4 0-.603-.234-.603-.663v-.51c0-.44.204-.664.603-.664h3.097c.399 0 .603.234.603.663v.194c0 .337-.074.572-.269.828l-.927 1.174c1.038.296 1.52 1.195 1.52 2.032 0 1.246-.76 2.574-2.568 2.574-.881 0-1.493-.276-1.902-.551-.306-.245-.333-.572-.139-.93zM7.51 5.831h18.643c.468 0 .847-.417.847-.933V2.566c0-.515-.38-.933-.847-.933H7.51c-.468 0-.848.418-.848.933V4.9c0 .516.38.933.848.933zm0 9.332h18.643c.468 0 .847-.418.847-.933v-2.333c0-.515-.38-.933-.847-.933H7.51c-.468 0-.848.418-.848.933v2.333c0 .515.38.933.848.933zm0 9.332h18.643c.468 0 .847-.418.847-.933V21.23c0-.516-.38-.933-.847-.933H7.51c-.468 0-.848.417-.848.933v2.333c0 .515.38.933.848.933z"
      fill="#1A3852"
      fillRule="nonzero"
      fillOpacity=".6"
    />
  </svg>
)

export default Leaderboard
