import React from 'react'
import { Link } from '@reach/router'
import styled from 'styled-components'

import { Logo } from './Icons'
import sap from '../images/poweredBySAP.png'

const HeaderContainer = styled.header`
  width: 100%;
  background: ${props => props.theme.colors.secondary};
  box-sizing: border-box;
  padding: ${props => props.theme.spacing.x3};
  display: flex;
  justify-content: space-between;
`

const Image = styled.img`
  max-width: 100%;
`

const LogoLink = ({ embed, children }) =>
  embed ? (
    <a href="https://www.equiratings.com" target="_blank">{children}</a>
  ) : (
    <Link to={embed ? '' : '/'} aria-label="Link to EquiRatings">
      {children}
    </Link>
  )

const Header = ({ embed }) => (
  <HeaderContainer role="banner">
    <LogoLink embed={embed}>
      <Logo />
    </LogoLink>
    <a href="https://www.sap.com" target="_blank">
      <Image src={sap} alt="powered by SAP" width="69" height="36" />
    </a>
  </HeaderContainer>
)

export default Header
