import React, { useState } from 'react'
import styled from 'styled-components'

import theme from '../lib/theme'

import { Link } from '@reach/router'
import { Leaderboard, Movement, Voting } from './Icons'
import Text from './Text'

const Ul = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
`

const Li = styled.li`
  padding-left: ${({ theme }) => theme.spacing.x3};
`

const NavLink = props => (
  <Link
    {...props}
    to={props.to + window.location.search}
    style={{
      paddingBottom: '5px',
      borderBottom:
        props.to === window.location.pathname
          ? '2px solid rgba(26, 56, 82, 1)'
          : 'none'
    }}
  />
)
const CompetitionNav = ({ competitionId, phase, setView }) => {

  const setLocalStorage = tab => {
    window.localStorage.setItem('selectedTab', tab)
    setView(tab)
  }

  return (  
    <> 
    <nav>
    <Ul>
      <Li>
        <NavLink
          to={`/competitions/${competitionId}/`}
          aria-label="Leaderboard"
          onClick={() => setLocalStorage(0)}
        >
          <Leaderboard />
        </NavLink>
      </Li>
      <Li>
        <NavLink
          to={`/competitions/${competitionId}/movement`}
          aria-label="Movement"
          onClick={() => setLocalStorage(1)}
        >
          <Movement />
        </NavLink>
      </Li>
      <Li>
        <NavLink
          to={`/competitions/${competitionId}/voting`}
          aria-label="Voting"
          onClick={() => setLocalStorage(2)}
        >
          <Voting />
        </NavLink>
      </Li>
    </Ul>
  </nav>
  </>
)
}

export default CompetitionNav
