import React, { useState, useEffect, createContext } from 'react'
import styled, { withTheme } from 'styled-components'
import { CSSTransition } from 'react-transition-group'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from 'body-scroll-lock'

import { get } from '../lib/network'
import { competitionName, currentPhaseSimulations } from '../lib/helpers'
import { findAncestor } from '../lib/utils'
import theme from '../lib/theme'

import ErrorMessage from '../components/ErrorMessage'
import Loading from '../components/Loading'
import Text from '../components/Text'
import Spacer from '../components/Spacer'
import PlayerView from '../components/PlayerView'
import CompetitionNav from '../components/CompetitionNav'
import Introduction from '../components/Introduction'
import Link, { WidgetLink } from '../components/Link'
const Padding = styled.div`
  padding: 0 ${props => props.theme.spacing.x3};
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

const CompetitionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 40%;
  align-items: center;
  @media (${theme.breakpoints.mediumAndUp}) {
    width: 100%;
  }
`


const PhaseButton = styled.button`
  border: none;
  padding-bottom: ${theme.spacing.x2};
  margin-right: ${theme.spacing.x3};
  background-color: transparent;
  text-align: center;
  margin-top:${theme.spacing.x2}
  border-left: 2px inset
    ${({active}) =>
      active ? `${theme.colors.secondary}` : 'transparent'};
  border-bottom: 0;
  outline: none;
  cursor: pointer;
  @media (${theme.breakpoints.large}){
    border-left: 0px solid transparent;
     border-bottom: 2px solid
    ${({active}) =>
      active ? `${theme.colors.secondary}` : 'transparent'};
      margin-top: 0;
  }
  &:first-child {
    margin-top: 0;
  }
`

export const CompetitionContext = createContext(null)

const Competition = ({ competitionId, theme, children, embed }) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [orderBy, setOrderBy] = useState('one')
  const [activeTab, setActiveTab] = useState(0)
  const [selectedView, setView] = useState(parseInt(localStorage.getItem('selectedTab')) || 0)
  const [modal, setModal] = useState({
    focusedPlayerID: null,
    modalStartPosition: null
  })

  let viewDescription

    switch(selectedView) {
      case 1:
        viewDescription =  'Impact on win chance'
        break;
      case 2:
        viewDescription = 'Fan voting'
        break;
      default:
        viewDescription = 'Percentage win chance'
    }

  useEffect(() => {
    get('/api/v1/competition_simulations/' + competitionId, {
      setLoading,
      setError,
      setData
    })
  }, [])

  useEffect(() => clearAllBodyScrollLocks, [])

  const handleFocusOnPlayer = (id, e) => {
    e.preventDefault()
    const targetElement = document.querySelector('#player-view')
    id ? disableBodyScroll(targetElement) : enableBodyScroll(targetElement)

    const parent = findAncestor(e.target, '.row')
    const pos = parent ? parent.getBoundingClientRect() : null
    setModal(prevState => ({
      modalStartPosition: pos || prevState.modalStartPosition,
      focusedPlayerID: id
    }))
  }

  const currentPhaseSimulation =
    data && data.phaseSimulations



  return (
    <>
    {error && <ErrorMessage error={error} />}
     {loading || !data ? (
        <Loading />
      ) : (
        <>
        {embed ? <Introduction embed={embed} /> : null}
        <CompetitionContext.Provider
          value={{
            data: currentPhaseSimulation,
            handleOrderBy: setOrderBy,
            activeTab,
            orderBy,
            handleFocusOnPlayer
          }}
        >
          <Spacer size={theme.spacing.x4} />
          <Padding>
            <CompetitionContainer>
            <Text
              tag="h1"
              size={[theme.fontScale.x5]}
              weight={theme.fontWeights.bold}
              cursor="pointer"
            >
              {`${competitionName(data)} - ${viewDescription}`}
            </Text>
            <Spacer size={theme.spacing.x2} />
            <Spacer size={theme.spacing.x4} />
             <Text
              tag="h1"
              size={[theme.fontScale.x4]}
              weight={theme.fontWeights.normal}
              lineHeight={theme.lineHeights.heading}
              cursor="pointer"
            >
              {data.competitionArticleUrl ? (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <WidgetLink style={{ paddingRight: '15px', borderRight: '1px solid #DEDEDE' }} href={'http://www.equiratings.com/articles/adelaide-graphics'} target= "_blank">{`Graphics`}</WidgetLink>
                <WidgetLink style={{ paddingLeft: '15px' }} href={data.competitionArticleUrl} target= "_blank">{`SAP Eventing Insights blog here`}</WidgetLink>
                </div>
              ) : null}
           </Text>
        </CompetitionContainer>
        {selectedView === 2 ? (
          <>
          <Spacer size={theme.spacing.x3} />
          <Text
                      tag="h1"
                      size={[theme.fontScale.x3]}
                      weight={theme.fontWeights.normal}
                      lineHeight={theme.lineHeights.heading}
                      cursor="pointer"
                    >
                      This screen shows what proportion of first, second, and third choice votes each combination has received from fans.
                    </Text>
          </>
        ) : null}
          <Spacer size={theme.spacing.x4} />
            <Row>
            <ButtonsContainer>
              <>
                {selectedView !== 2 ?(
                  <>
                  {currentPhaseSimulation.map((phase, index)=> (
                    <PhaseButton active={activeTab === index} key={index} onClick={() => setActiveTab(index)}>
                      <Text
                        tag="h1"
                        size={[theme.fontScale.x4]}
                        weight={theme.fontWeights.bold}
                        lineHeight={theme.lineHeights.heading}
                        cursor="pointer"
                      >
                          {phase.phaseType}
                      </Text>
                      </PhaseButton>
                    ))}
                  </>
                ) :null}
              </>
            </ButtonsContainer>

            <CompetitionNav setView={setView} phase={currentPhaseSimulation[currentPhaseSimulation.length - 1].phaseType} competitionId={competitionId} />
            </Row>
            </Padding>

          <Spacer size={theme.spacing.x4} />
          <Spacer size={theme.spacing.x3} />
            {children}
          <Spacer size={theme.spacing.x3} />
          <CSSTransition
            in={modal.focusedPlayerID !== null}
            classNames="modal"
            timeout={600}
          >
            <PlayerView
              playerID={modal.focusedPlayerID}
              data={data}
              handleFocusOnPlayer={handleFocusOnPlayer}
              modalStartPosition={modal.modalStartPosition}
            />
          </CSSTransition>
        </CompetitionContext.Provider>
        </>
      )}
    </>
  )
}

export default withTheme(Competition)
