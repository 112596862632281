import React from 'react'
import styled from 'styled-components'

import theme from '../lib/theme'
import Text from './Text'
import Spacer from './Spacer'

const Td = styled.td`
  width: 49%;
  padding-right: ${theme.spacing.x2};
  cursor: pointer;
`

const DataName = ({ datum }) => (
  <Td>
    <Spacer size={theme.spacing.x2} />
    <Text
      tag="p"
      size={[theme.fontScale.x3]}
      weight={theme.fontWeights.bold}
      lineHeight={theme.lineHeights.heading}
      cursor="pointer"
    >
      {`${datum.athleteFirstname} ${datum.athleteSurname || ''}`}
    </Text>
    <Text tag="p" size={[theme.fontScale.x2]} cursor="pointer">
      {datum.horseName}
    </Text>
  </Td>
)

export default DataName
